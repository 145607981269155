<template>
  <Layout :pagetitle="pagetitle">

    <div class="container-fluid">
      <div class="row">
        <div class="col-9">
          <h2>{{ officeName }}</h2>
        </div>
        <div class="col-3 d-flex justify-content-end pb-3">
          <img class="img-fluid" src="@/assets/images/logo.png" style="max-height: 55px"/>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row row-cols-2">
        <div class="col-sm-9">
          <div class="row mb-2">
            <div class="col-9">
              <div class="row g-2 mb-2">
                <div class="col">
                  <CommunityCard :communities="totalCommunities" :loading="loading.communityStatistics"/>
                </div>

                <div class="col">
                  <OwnersCard :owners="ownerData.total_owners" :loading="loading.ownerStatistics"/>
                </div>

                <div class="col">
                  <PropertyFeesCard :propertyFees="propertyFees" :loading="loading.communityStatistics"/>
                </div>
              </div>

              <div class="row row-cols-1 row-cols-md-3 g-2 mb-1">
                <div class="col">
                  <OwnersWithEmailCard
                    :totalOwners="ownerData.total_owners"
                    :ownersWithEmail="ownerData.total_owners_with_email"
                    :loading="loading.ownerStatistics"
                  />
                </div>
                <div class="col">
                  <OwnersWithBankAccountCard
                    :totalOwners="ownerData.total_owners"
                    :ownersWithBankAccount="ownerData.total_owners_with_bank_account"
                    :loading="loading.ownerStatistics"
                  />
                </div>
                <div class="col">
                  <DefaultRatioCard
                    :totalOwners="ownerData.total_owners"
                    :defaultingOwners="ownerData.total_defaulting_owners"
                    :loading="loading.ownerStatistics"
                  />
                </div>
              </div>

            </div>
            <div class="col-3">
              <div class="row">
                <CommunityRentabilityCard
                  :communityRentability="communityRentability"
                  :loading="loading.communityStatistics"
                />
              </div>
            </div>
          </div>

          <div class="row row-cols-2">
            <div class="col-9">
              <div class="row g-1">
                <IncidencesStatsCard
                  v-if="this.showIncidences"
                  :meanResolutionTime="meanResolutionTime"
                  :incidencesEvolution="incidencesEvolutionPerWeek"
                  :loading="loading.incidencesEvolution"
                />
                <div v-else>
                  <GoogleMap
                    :api-key="this.googleMapsApiKey"
                    :loading="this.loading.communitiesLocations"
                    :communities-locations="this.communitiesLocations">
                  </GoogleMap>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="row">
                <IncidencesByJobCard
                  :totalIncidences="supplierOccupationIncidences.total_opened_incidences"
                  :jobList="supplierOccupationIncidences.supplier_occupation_incidences_list"
                  :loading="loading.supplierOccupationIncidencesStatistics"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="row">
            <div class="col-sm">
              <RentabilityCard
                :workHoursSaved="totalSavingHours"
                :automaticInvoices="automaticInvoices"
                :totalInvoices="totalInvoices"
                :totalTransactions="totalTransactions"
                :categorizedTransactions="automaticTransactions"
                :loading="loading.productivityStatistics"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>
import Layout from "../../../layouts/main.vue";
import appConfig from "@/app.config";
import CommunityCard from "./components/community-card.vue";
import OwnersCard from "./components/owners-card.vue";
import OwnersWithEmailCard from "./components/owners-with-email-card.vue";
import OwnersWithBankAccountCard from "./components/owners-with-bank-account-card.vue";
import DefaultRatioCard from "./components/default-ratio-card.vue";
import IncidencesStatsCard from "./components/incidences-stats-card.vue";
import CommunityRentabilityCard from "./components/community-rentability-card.vue";
import IncidencesByJobCard from "./components/incidences-by-job-card.vue";
import RentabilityCard from "./components/rentability-card.vue";
import {IncidencesEvolutionApi} from "../../../../api/incidences/incidences-evolution-api";
import {CommunityStatisticsApi} from "../../../../api/community/communities-statistics-api";
import {OwnerStatisticsApi} from "../../../../api/owner/owner-statistics-api";
import {ProductivityStatisticsApi} from "../../../../api/administrator/productivity-statistics-api";
import {SupplierOccupationIncidencesApi} from "../../../../api/incidences/supplier-occupation-incidences-api";
import {DateHelper} from "../../../../api/common/helpers/date-helper";
import {useRoute} from "vue-router";
import PropertyFeesCard from "./components/property-fees-card.vue";
import GoogleMap from "@/views/pages/dashboard/fynkus/components/google-map";
import {CommunitiesLocationsApi} from "@/api/incidences/communities-locations-api";

export default {
  page: {
    title: "Fynkus",
    meta: [
      {
        name: "Fynkus Statistics Panel",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    CommunityCard,
    OwnersCard,
    OwnersWithEmailCard,
    OwnersWithBankAccountCard,
    DefaultRatioCard,
    IncidencesStatsCard,
    CommunityRentabilityCard,
    IncidencesByJobCard,
    RentabilityCard,
    PropertyFeesCard,
    GoogleMap
  },
  mounted() {
    const route = useRoute();
    const token = route.query.token;
    this.updateStats(token);
  },
  methods: {
    async updateStats(token) {
      try {
        await Promise.all([
          this.fetchCommunityStatistics(token),
          this.fetchOwnerStatistics(token),
          this.fetchIncidencesEvolution(token),
          this.fetchCommunitiesLocations(token),
          this.fetchProductivityStatistics(token),
          this.fetchSupplierOccupationIncidencesStatistics(token)
        ]);

      } catch (error) {
        if (error.type === 'InvalidStatsApiTokenException') {
          this.$router.push({name: 'unauthorized'})
        }
      }
    },
    getDateRange(days = 30) {
      const startDate = DateHelper.substractDays(new Date(), days);
      const endDate = new Date();
      return {
        start_date: `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}`,
        end_date: `${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear()}`,
      };
    },
    async fetchCommunityStatistics(token) {
      this.loading.communityStatistics = true;
      const params = this.getDateRange();
      const communityData = await CommunityStatisticsApi.fetchCommunityStatistics(this.webapi, token, params);

      this.officeName = communityData.office_name;
      this.totalCommunities = communityData.total_communities;
      this.propertyFees = communityData.property_average_fees;
      this.communityRentability = communityData.rentability;
      this.loading.communityStatistics = false;
    },
    async fetchOwnerStatistics(token) {
      this.loading.ownerStatistics = true;
      this.ownerData = await OwnerStatisticsApi.fetchOwnerStatistics(this.webapi, token);
      this.loading.ownerStatistics = false;
    },
    async fetchProductivityStatistics(token) {
      this.loading.productivityStatistics = true;
      const params = this.getDateRange();
      const productivityData = await ProductivityStatisticsApi.fetchProductivityStatistics(this.webapi, token, params);

      this.totalInvoices = productivityData.recurring_invoices + productivityData.manual_invoices;
      this.automaticInvoices = productivityData.recurring_invoices;
      this.totalSavingHours = Math.round(productivityData.recurring_invoices_saving_hours +
          productivityData.automatic_transactions_saving_hours);
      this.automaticTransactions = productivityData.automatic_transactions;
      this.totalTransactions = productivityData.transactions_total;
      this.loading.productivityStatistics = false;
    },
    async fetchIncidencesEvolution(token) {
      this.loading.incidencesEvolution = true;
      const params = this.getDateRange(90);
      const incidencesEvolution = await IncidencesEvolutionApi.fetchIncidencesEvolutionStats(this.webapi, token, params);

      this.meanResolutionTime = incidencesEvolution.mean_time_of_resolution;
      this.incidencesEvolutionPerWeek = incidencesEvolution.incidences_evolution_per_week_data;
      this.loading.incidencesEvolution = false;
    },
    async fetchCommunitiesLocations(token) {
      this.loading.communitiesLocations = true;
      this.communitiesLocations = await CommunitiesLocationsApi.fetchCommunitiesLocations(this.webapi, token);
      this.loading.communitiesLocations = false;

      if (this.communitiesLocations.length > 0) {
        this.startTimer();
      }
    },
    async fetchSupplierOccupationIncidencesStatistics(token) {
      this.loading.supplierOccupationIncidencesStatistics = true;
      const params = this.getDateRange(90);
      this.supplierOccupationIncidences = await SupplierOccupationIncidencesApi.fetchSupplierOccupationIncidencesStatistics(
          this.webapi,
          token,
          params
      );
      this.loading.supplierOccupationIncidencesStatistics = false;
    },
    startTimer() {
      this.showIncidencesInterval = setInterval(() => {
        this.showIncidences = !this.showIncidences;
      }, 60000);
    },
  },
  data() {
    return {
      pagetitle: "Fynkus",
      totalCommunities: 0,
      propertyFees: 0,
      communityRentability: [],
      webapi: process.env.VUE_APP_BASE_WEBAPI,
      googleMapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_GEOCODING_API_KEY,
      ownerData: {
        total_owners: 0,
        total_owners_with_email: 0,
        total_owners_with_bank_account: 0,
        total_defaulting_owners: 0,
      },
      supplierOccupationIncidences: {
        supplier_occupation_incidences_list: []
      },
      totalInvoices: 0,
      automaticInvoices: 0,
      totalSavingHours: 0,
      automaticTransactions: 0,
      totalTransactions: 0,
      meanResolutionTime: 0,
      incidencesEvolutionPerDay: [],
      communitiesLocations: [],
      officeName: "",
      loading: {
        communityStatistics: false,
        ownerStatistics: false,
        incidencesEvolution: false,
        productivityStatistics: false,
        supplierOccupationIncidencesStatistics: false,
        communitiesLocations: false,
      },
      showIncidences: true,
      showIncidencesInterval: null
    };
  }
};
</script>

<style lang="scss" scoped>
.development-version-label {
  color: #56BDAD;
}
</style>
