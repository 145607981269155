export class StringHelper {

  static removeQuotes(text) {
    return text.replace(/['"]+/g, '');
  }

  static removeWhiteSpaces(text) {
    return text.replaceAll(/\s/g,'');
  }

  static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
