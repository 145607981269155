<template>
    <div class="user-sidebar">
        <div class="card">
            <div class="card-body">
                <div class="user-profile-img">
                    <img src="@/assets/images/pattern-bg.jpg" class="profile-img profile-foreground-img rounded-top"
                        style="height: 120px" alt="" />
                </div>
                <!-- end user-profile-img -->

                <div class="mt-n5 position-relative">
                    <div class="text-center">
                        <img src="@/assets/images/users/avatar-robot.png" alt=""
                            class="avatar-xl rounded-circle img-thumbnail" />
                    </div>
                </div>

                <div class="text-center mt-2 mb-2 border-top">
                    <div class="row">
                        <div class="col-sm">
                            <spinner v-if="loading"></spinner>

                            <div class="pt mt-2" v-if="!loading">
                                <h3>Ahorro en los últimos 30 días</h3>
                                <p class="display-4" v-if="hasWorkHoursSaved()">{{ workHoursSaved }} horas</p>
                                <NoDataMessage v-if="!hasWorkHoursSaved()"></NoDataMessage>
                            </div>
                        </div>
                    </div>

                    <div class="text-center mt-2 mb-2 border-top">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <spinner v-if="loading"></spinner>

                                <div v-if="!loading">
                                    <div class="d-flex align-items-start">
                                        <div class="flex-grow-1 mt-2">
                                            <h5 class="card-title mb-1">Movimientos banco</h5>
                                        </div>
                                    </div>

                                    <NoDataMessage v-if="!hasTransactionsData()"></NoDataMessage>

                                    <div v-if="hasTransactionsData()">
                                        <apexchart class="mt-2" height="162" type="radialBar" :options="apexChartOptions"
                                            :series="transactionsPercentage()" />
                                            <div class="text-center mt-3 border-top">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="pt-1">
                                                            <p class="text-muted text-truncate mb-2">Total</p>
                                                            <h5 class="font-size-16 mb-0">{{
                                                                    totalTransactions.toLocaleString('es-ES')
                                                            }}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="pt-1">
                                                            <p class="text-muted text-truncate mb-2">Automáticos</p>
                                                            <h5 class="font-size-16 mb-0">{{
                                                                    categorizedTransactions.toLocaleString('es-ES')
                                                            }}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                  </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <spinner v-if="loading"></spinner>

                                <div v-if="!loading">
                                    <div class="d-flex align-items-start">
                                      <div class="flex-grow-1 mt-2">
                                        <h5 class="card-title mb-1">Facturas proveedores</h5>
                                      </div>
                                    </div>

                                    <NoDataMessage v-if="!hasInvoicesData()"></NoDataMessage>

                                    <div v-if="hasInvoicesData()">
                                        <apexchart class="mt-2" height="162" type="radialBar" :options="apexChartOptions"
                                                   :series="invoicesPercentage()" />
                                        <div class="text-center mt-3 border-top">
                                          <div class="row">
                                            <div class="col-6">
                                              <div class="pt-1">
                                                <p class="text-muted text-truncate mb-2">Total</p>
                                                <h5 class="font-size-16 mb-0">{{
                                                    totalInvoices.toLocaleString('es-ES')
                                                  }}
                                                </h5>
                                              </div>
                                            </div>
                                            <div class="col-6">
                                              <div class="pt-1">
                                                <p class="text-muted text-truncate mb-2">Automáticas</p>
                                                <h5 class="font-size-16 mb-0">{{
                                                    automaticInvoices.toLocaleString('es-ES')
                                                  }}
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- end card body -->
        </div>
        <!-- end card -->
    </div>
</template>

<script>
import spinner from '../../../../../components/spinner.vue';
import NoDataMessage from "@/views/pages/dashboard/fynkus/components/no-data-message.vue";
export default {
    components: {NoDataMessage, spinner },
    name: "RentabilityCard",
    props: {
        workHoursSaved: {
            type: Number,
        },
        totalTransactions: {
            type: Number,
        },
        totalInvoices: {
            type: Number,
        },
        automaticInvoices: {
            type: Number,
        },
        categorizedTransactions: {
            type: Number,
        },
        loading: {
          type: Boolean,
          default: false,
        },
    },
    data() {
        return {
            apexChartOptions: {
                chart: {
                    type: "radialBar",
                    height: 162,
                    sparkline: {
                        enabled: true,
                    },
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: "#f3f2f9",
                            strokeWidth: "97%",
                            margin: 5, // margin is in pixels
                            dropShadow: {
                                enabled: false,
                                top: 2,
                                left: 0,
                                color: "#999",
                                opacity: 1,
                                blur: 2,
                            },
                        },
                        hollow: {
                            margin: 15,
                            size: "65%",
                        },
                        dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                offsetY: -2,
                                fontSize: "22px",
                            },
                        },
                    },
                },
                stroke: {
                    lineCap: "round",
                },
                grid: {
                    padding: {
                        top: -10,
                    },
                },
                colors: ["#3b76e1"],
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "light",
                        shadeIntensity: 0.4,
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 50, 53, 91],
                    },
                },
                labels: ["Average Results"],
            },
        }
    },
    methods: {
        hasWorkHoursSaved() {
            return this.workHoursSaved > 0;
        },
        hasTransactionsData() {
            return this.totalTransactions > 0;
        },
        hasInvoicesData() {
            return this.totalInvoices > 0;
        },
        transactionsPercentage() {
            const result = this.totalTransactions > 0 ? (this.categorizedTransactions / this.totalTransactions) * 100 : 0;
            return [
                result.toFixed(2)
            ];
        },
        invoicesPercentage() {
            const result = this.totalInvoices > 0 ? (this.automaticInvoices / this.totalInvoices) * 100 : 0;
            return [
                result.toFixed(2)
            ];
        },
    },
    updated() {
        this.$nextTick(() => {
            window.dispatchEvent(new Event('resize'));
        });
    }
}
</script>

<style>
</style>