import { HttpHelper } from '../common/helpers/http-helper';

export class CommunitiesLocationsApi {
    static async fetchCommunitiesLocations(baseurl, token) {
        const url = `${baseurl}/communities-locations`;

        const headers = {
            "Authorization": token
        }

        return await HttpHelper.getJson(url, null, headers);
    }
}