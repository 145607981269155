<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex align-items-start">
                <div class="flex-grow-1">
                    <h5 class="card-title mb-3">Propietarios con banco</h5>
                </div>
            </div>
            <spinner v-if="loading" />

            <div v-if="!loading">
                <NoDataMessage v-if="!hasData()"></NoDataMessage>

                <div v-if="hasData()">
                    <apexchart class="mt-2" height="162" type="radialBar" :options="apexChartOptions"
                        :series="ownersWithBankAccountPercentage()" />
                    <div class="text-center mt-4 border-top">
                        <div class="row">
                            <div class="col-6">
                                <div class="pt-3">
                                    <p class="text-muted text-truncate mb-2">Total</p>
                                    <h5 class="font-size-16 mb-0">{{ totalOwners.toLocaleString('es-ES') }}</h5>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="pt-3">
                                    <p class="text-muted text-truncate mb-2">Con banco</p>
                                    <h5 class="font-size-16 mb-0">{{ ownersWithBankAccount.toLocaleString('es-ES') }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import spinner from '../../../../../components/spinner.vue';
import NoDataMessage from "@/views/pages/dashboard/fynkus/components/no-data-message.vue";
export default {
    components: {NoDataMessage, spinner },
    name: "OwnersWithBankAccountCard",
    props: {
        ownersWithBankAccount: {
            type: Number,
            default: 0,
        },
        totalOwners: {
            type: Number,
            default: 0,
        },
        loading: {
          type: Boolean,
          default: false,
        },
    },
    data() {
        return {
            apexChartOptions: {
                chart: {
                    type: "radialBar",
                    height: 162,
                    sparkline: {
                        enabled: true,
                    },
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: "#f3f2f9",
                            strokeWidth: "97%",
                            margin: 5, // margin is in pixels
                            dropShadow: {
                                enabled: false,
                                top: 2,
                                left: 0,
                                color: "#999",
                                opacity: 1,
                                blur: 2,
                            },
                        },
                        hollow: {
                            margin: 15,
                            size: "65%",
                        },
                        dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                offsetY: -2,
                                fontSize: "22px",
                            },
                        },
                    },
                },
                stroke: {
                    lineCap: "round",
                },
                grid: {
                    padding: {
                        top: -10,
                    },
                },
                colors: ["#3b76e1"],
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "light",
                        shadeIntensity: 0.4,
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 50, 53, 91],
                    },
                },
                labels: ["Average Results"],
            },
        }
    },
    methods: {
        hasData() {
            return this.totalOwners > 0;
        },
        ownersWithBankAccountPercentage() {
            const result = this.totalOwners > 0 ? (this.ownersWithBankAccount / this.totalOwners) * 100 : 0;
            return [
                result.toFixed(2)
            ];
        },
    },
    updated() {
        this.$nextTick(() => {
            window.dispatchEvent(new Event('resize'));
        });
    }
}
</script>

<style>
</style>