<template>
    <div class="card incidences-by-job-card">
        <div class="card-body">
            <div class="d-flex align-items-start">
                <div class="flex-grow-1">
                    <h5 class="card-title mb-3">Incidencias por oficios en los últimos 90 días</h5>
                </div>
            </div>

            <spinner v-if="loading" />

            <div v-if="!loading">
              <NoDataMessage v-if="!hasIncidencesData"></NoDataMessage>

              <div class="row" v-if="hasIncidencesData">
                  <SimpleBar class="mx-n2" data-simplebar style="max-height: 400px">
                      <div class="row" v-for="(job, index) in jobList" v-bind:key="index">
                          <div class="row">
                              <div class="p-1 py-1">
                                  <p class="font-size-14 mb-0">{{ job.occupation }}</p>
                              </div>
                          </div>
                          <div class="col-sm-9">
                              <div class="p-0 py-1">
                                  <div class="progress animated-progess custom-progress">
                                      <div class="progress-bar bg-gradient bg-primary" role="progressbar"
                                          aria-valuemin="0" v-bind:aria-valuemax="totalIncidences"
                                          v-bind:aria-valuenow="job.total"
                                          :style="{ width: this.getProgress(totalIncidences, job.total) + '%' }">
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-3">
                              <div class="p-0 py-1">
                                  <h5 class="font-size-16 mb-0">{{ job.total }}</h5>
                              </div>
                          </div>
                      </div>
                  </SimpleBar>
              </div>
            </div>
            <!-- end row -->
        </div>
        <!-- end card body -->
    </div>
</template>

<script>
import spinner from '../../../../../components/spinner.vue';
import { SimpleBar } from "simplebar-vue3";
import NoDataMessage from "@/views/pages/dashboard/fynkus/components/no-data-message.vue";

export default {
    components: {NoDataMessage, spinner, SimpleBar },
    name: "IncidencesByJobCard",
    props: {
        totalIncidences: {
            type: Number,
            default: 0
        },
        jobList: {
            type: Array,
            default: () => []
        },
        loading: {
          type: Boolean,
          default: false,
        },
    },
    methods: {
        getProgress(total, current) {
            return total > 0 ? (current * 100) / total : 0;
        }
    },
    computed: {
        hasIncidencesData() {
            return this.totalIncidences > 0;
        }
    }
}
</script>

<style>
</style>