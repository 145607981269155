<template>
    <div class="card property-fees-card">
        <div class="card-body">
            <spinner v-if="loading" />

            <div v-if="!loading">
                <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                        <div class="avatar">
                            <div class="avatar-title rounded bg-primary bg-gradient">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-currency-euro" viewBox="0 0 16 16">
                                    <path
                                        d="M4 9.42h1.063C5.4 12.323 7.317 14 10.34 14c.622 0 1.167-.068 1.659-.185v-1.3c-.484.119-1.045.17-1.659.17-2.1 0-3.455-1.198-3.775-3.264h4.017v-.928H6.497v-.936c0-.11 0-.219.008-.329h4.078v-.927H6.618c.388-1.898 1.719-2.985 3.723-2.985.614 0 1.175.05 1.659.177V2.194A6.617 6.617 0 0 0 10.341 2c-2.928 0-4.82 1.569-5.244 4.3H4v.928h1.01v1.265H4v.928z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <p class="text-muted mb-1">Honorarios medios por vivienda</p>
                        <div v-if="hasPropertyFeesData()">
                          <span class="mb-0 h4">{{ propertyFees.toLocaleString('es-ES') }} €/mes</span>
                          <span> (sin IVA)</span>
                        </div>
                      <NoDataMessage v-if="!hasPropertyFeesData()"></NoDataMessage>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import spinner from '../../../../../components/spinner.vue';
import NoDataMessage from "@/views/pages/dashboard/fynkus/components/no-data-message.vue";
export default {
    components: {NoDataMessage, spinner },
    name: "PropertyFeesCard",
    props: {
        propertyFees: {
            type: Number,
            default: 0,
        },
        loading: {
          type: Boolean,
          default: false,
        },
    },
    methods: {
        hasPropertyFeesData() {
            return this.propertyFees > 0;
        }
    }
};
</script>

<style>
</style>