<template>
  <div class="card community-rentability-card" style="max-height: 465px" @mouseenter="stopTimer"
       @mouseleave="startTimer">

    <spinner v-if="loading"/>

    <div v-if="!loading">
      <div class="card-body">
        <div class="d-flex align-items-start">
          <div class="flex-grow-1">
            <h5 class="card-title mb-3" v-if="showMostProfitableCommunities">Comunidades más rentables</h5>
            <h5 class="card-title mb-3" v-else>Comunidades menos rentables</h5>
          </div>
        </div>

        <NoDataMessage v-if="!hasData()"></NoDataMessage>

        <div v-if="hasData()">
          <SimpleBar data-simplebar style="max-height: 322px" class="px-2">
            <div v-for="(community, index) in communityList" :key="index" data-test="communities" class="py-1">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-sm">
                    <div class="avatar-title bg-primary bg-gradient rounded" v-if="showMostProfitableCommunities">
                      {{ index + 1 }}
                    </div>
                    <div class="avatar-title bg-red bg-gradient rounded" v-else>
                      {{ index + 1 }}
                    </div>
                  </div>
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <p class="text-muted mb-1 text-truncate">
                    {{ community.community_name }}
                  </p>
                </div>
                <!-- <div class="flex-shrink-0">
                    <h5 class="font-size-14 mb-0 text-truncate w-xs bg-light p-2 rounded text-center">
                      {{ community.rentability.toLocaleString('es-ES', {minimumFractionDigits: 2}) }} %
                    </h5>
                  </div> -->
              </div>
            </div>
          </SimpleBar>
        </div>
      </div>
    </div>
    <spinner v-else/>
  </div>
</template>

<script>
import {SimpleBar} from "simplebar-vue3";
import spinner from '../../../../../components/spinner.vue';
import NoDataMessage from "@/views/pages/dashboard/fynkus/components/no-data-message.vue";

export default {
  name: 'CommunityRentabilityCard',
  components: {
    NoDataMessage,
    SimpleBar,
    spinner
  },
  props: {
    communityRentability: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMostProfitableCommunities: true,
      showMostProfitableCommunitiesInterval: null,
    }
  },
  computed: {
    communityList() {
      return this.showMostProfitableCommunities ?
          this.communityRentability : this.communityRentability.slice().reverse();
    }
  },
  methods: {
    hasData() {
      return this.communityRentability.length > 0;
    },
    profitableCommunitiesInfo() {
      this.startTimer();
    },
    startTimer() {
      this.showMostProfitableCommunitiesInterval = setInterval(() => {
        this.showMostProfitableCommunities = !this.showMostProfitableCommunities;
      }, 15000);
    },
    stopTimer() {
      clearInterval(this.showMostProfitableCommunitiesInterval);
    }
  },
  created() {
    this.profitableCommunitiesInfo();
  },
}
</script>

<style>
</style>