import {StringHelper} from "./string-helper.js";
import {DateTime} from "luxon";

export class DateHelper {

  static addMonths(date, months) {
    return DateTime.fromJSDate(date).plus({months: months}).toJSDate();
  }

  static formatMonthAndYear(date) {
    const options = {year: 'numeric', month: 'long'};

    return StringHelper.capitalizeFirstLetter(new Intl.DateTimeFormat('es-ES', options).format(date));
  }

  static diffInDays(firstDate, secondDate) {
    const differenceInMilliseconds = secondDate.getTime() - firstDate.getTime();

    return differenceInMilliseconds / (1000 * 3600 * 24);
  }

  static extractYear(date) {
    return date.getFullYear();
  }

  static substractDays(date, days) {
    return DateTime.fromJSDate(date).plus({days: days * -1}).toJSDate();
  }
}
