<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-start">
        <div class="flex-grow-1">
          <h5 class="card-title mb-2">Ubicación comunidades</h5>
        </div>
      </div>
      <div id="map"></div>
    </div>
  </div>

</template>

<script>
import {Loader} from "@googlemaps/js-api-loader";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

export default {
  name: "GoogleMap",
  props: {
    apiKey: String,
    communitiesLocations: Array,
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      google: null,
    }
  },
  watch: {
    communitiesLocations: {
      handler() {
        this.initializeMap();
      }
    },
  },
  async mounted() {
    const googleMapApi = await new Loader({
      apiKey: this.apiKey
    });

    this.google = await googleMapApi.load();
    this.google = window.google;

    this.initializeMap();
  },
  methods: {
    async loadMap() {
      const googleMapApi = await new Loader({
        apiKey: this.apiKey
      });

      this.google = await googleMapApi.load();
      this.google = window.google;

      this.initializeMap();
    },
    initializeMap() {
      const map = new this.google.maps.Map(document.getElementById("map"));
      this.addMarks(map);
    },
    addMarks(map) {
      const bounds = new this.google.maps.LatLngBounds();
      const markers = this.communitiesLocations.map((communityPosition) => {
        const position = {
          lat: communityPosition['latitude'],
          lng: communityPosition['longitude']
        };

        const marker = new this.google.maps.Marker({position});

        bounds.extend(position);
        return marker;
      });

      map.fitBounds(bounds);

      new MarkerClusterer({
        map,
        markers,
      });
    }
  }
}

</script>

<style scoped>

#map {
  width: 100%;
  height: 400px;
}

</style>