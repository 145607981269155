import { HttpHelper } from '../common/helpers/http-helper';

export class ProductivityStatisticsApi {
    static async fetchProductivityStatistics(baseurl, token, params, abortController = null) {
        const url = `${baseurl}/productivity-statistics`;

        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Authorization": token
        }

        return await HttpHelper.getJson(url, params, headers, abortController);
    }
}