import { HttpHelper } from '../common/helpers/http-helper';

export class SupplierOccupationIncidencesApi {
    static async fetchSupplierOccupationIncidencesStatistics(baseurl, token, params, abortController = null) {
        const url = `${baseurl}/supplier-occupation-incidences-statistics`;

        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Authorization": token
        }

        return await HttpHelper.getJson(url, params, headers, abortController);
    }
}