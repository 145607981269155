<template>
  <div class="card incidences-stats-card">
    <div class="card-body">
      <div class="d-flex align-items-start">
        <div class="flex-grow-1">
          <h5 class="card-title mb-2">Incidencias de los últimos 90 días</h5>
        </div>
      </div>
      <!-- end head -->
      <spinner v-if="loading"/>

      <div v-if="!loading">
        <NoDataMessage v-if="!hasIncidencesData()"></NoDataMessage>

        <div v-if="hasIncidencesData()">
          <div class="container">
            <div class="row fs-5">
              <div class="col-sm">
                <p class="text-start">Abiertas: {{ openedInPeriod() }}</p>
              </div>
              <div class="col-sm">
                <p class="text-center">Cerradas: {{ closedInPeriod() }}</p>
              </div>
              <div class="col-sm">
                <p class="text-end">Duración media: {{ toHoursAndMinutes(meanResolutionTime) }}</p>
              </div>
            </div>
          </div>

          <div class="mx-n2">
            <apexchart height="370px" type="area" :options="areaChart.chartOptions" :series="areaChart.series">
            </apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DateTime} from "luxon";
import spinner from '../../../../../components/spinner.vue';
import NoDataMessage from "@/views/pages/dashboard/fynkus/components/no-data-message.vue";

export default {
  components: {NoDataMessage, spinner},
  name: "IncidencesStatsCard",
  props: {
    incidencesEvolution: {
      type: Array,
      default: () => [],
    },
    meanResolutionTime: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    incidencesEvolution: {
      handler() {
        this.updateChart();
      }
    },
  },
  methods: {
    openedInPeriod() {
      return this.incidencesEvolution.reduce((n, {total_opened}) => n + total_opened, 0);
    },
    closedInPeriod() {
      return this.incidencesEvolution.reduce((n, {total_closed}) => n + total_closed, 0);
    },
    hasIncidencesData() {
      return this.incidencesEvolution.length > 0;
    },
    toHoursAndMinutes(totalSeconds) {
      const totalMinutes = totalSeconds / 60;
      const hours = Math.floor(totalMinutes / 60);
      const days = Math.floor(hours / 24);

      return `${days} días`;
    },
    updateChart() {
      this.areaChart.series = [
        {
          name: "Cerradas",
          data: this.evolutionClosed(),
        },
        {
          name: "Abiertas",
          data: this.evolutionOpened(),
        },
      ];
    },
    evolutionOpened() {
      return this.incidencesEvolution.map(incidence => {
        const [year, week] = incidence.date.split('/');
        const incidenceDate = DateTime.fromObject({
          weekYear: year,
          weekNumber: week
        });

        return {
          y: incidence.total_opened,
          x: incidenceDate.toJSDate()
        }
      });
    },
    evolutionClosed() {
      return this.incidencesEvolution.map(incidence => {
        const [year, week] = incidence.date.split('/');

        const incidenceDate = DateTime.fromObject({
          weekYear: year,
          weekNumber: week
        });

        return {
          y: incidence.total_closed,
          x: incidenceDate.toJSDate()
        }
      });
    },
  },
  data() {
    return {
      areaChart: {
        series: [
          {
            name: "Cerradas",
            data: this.evolutionClosed(),
          },
          {
            name: "Abiertas",
            data: this.evolutionOpened(),
          },
        ],
        chartOptions: {
          chart: {
            type: "area",
            height: 370,
          },
          dataLabels: {
            enabled: false
          },
          colors: ["#74bcac", "#eeeeee"],
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 4,
              opacityFrom: 1,
              opacityTo: 0.2,
              stops: [0, 100],
            },
          },
          stroke: {
            curve: "smooth",
            width: 1.5,
          },
          markers: {
            size: 0,
            hover: {
              size: 2,
            },
          },
          tooltip: {
            fixed: {
              enabled: true,
              offsetY: 25
            },
          },
          xaxis: {
            type: 'datetime',
            labels: {
              format: 'dd MMM'
            }
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return value;
              },
            },
            tickAmount: 8,
          },
        },
      }
    }
  }
}
</script>