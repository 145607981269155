<script>
import { layoutComputed } from "@/state/helpers";

/**
 * Vertical component
 */
export default {
  props: {
    pagetitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  created: () => {
    document.body.removeAttribute("data-layout");
    document.body.setAttribute("data-layout-mode", "dark");
  },
  computed: {
    ...layoutComputed,
  },
  methods: {
  },
};
</script>

<template>
  <div>
    <!-- Begin page -->
    <div id="layout-wrapper">
      <!-- ============================================================== -->
      <!-- Start right Content here -->
      <!-- ============================================================== -->
      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
