<script>
import { layoutComputed } from "@/state/helpers";
import Vertical from "./vertical";

export default {
  props: {
    pagetitle: {
      type: String,
      required: true,
    },
  },
  components: {
    Vertical,
  },
  data() {
    return {};
  },
  computed: {
    ...layoutComputed,
  },
  methods: {},
  mounted() {

  },
};
</script>

<template>
  <div>
    <Vertical :pagetitle="pagetitle" v-if="layoutType === 'vertical'" :layout="layoutType">
      <slot />
    </Vertical>
  </div>
</template>
