<template>
  <h6 class="mb-0 no-data-message">No hay datos</h6>
</template>

<script>
export default {
  name: "NoDataMessage",
};
</script>

<style scoped lang="scss">
.no-data-message {
  margin-top: 10px;
}
</style>