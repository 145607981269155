import { HttpHelper } from '../common/helpers/http-helper';

export class IncidencesEvolutionApi {
    static async fetchIncidencesEvolutionStats(baseurl, token, params, abortController = null) {
        const url = `${baseurl}/incidences-evolution`;

        const headers = {
            "Authorization": token
        }

        return await HttpHelper.getJson(url, params, headers, abortController);
    }
}