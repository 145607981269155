import { HttpHelper } from '../common/helpers/http-helper';

export class OwnerStatisticsApi {
    static async fetchOwnerStatistics(baseurl, token, abortController = null) {
        const url = `${baseurl}/owner-statistics`;

        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Authorization": token
        }

        return HttpHelper.getJson(url, [], headers, abortController);
    }
}