<template>
    <div class="card community-card">
        <div class="card-body">
            <spinner v-if="loading" />

            <div v-if="!loading">
                <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                        <div class="avatar">
                            <div class="avatar-title rounded bg-primary bg-gradient">
                                <eva-icon name="home" class="fill-white"></eva-icon>
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <p class="text-muted mb-1">Número de comunidades</p>
                        <h4 class="mb-0" v-if="hasCommunityData()">{{ communities.toLocaleString('es-ES') }}</h4>
                        <NoDataMessage v-if="!hasCommunityData()"></NoDataMessage>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import spinner from '../../../../../components/spinner.vue';
import NoDataMessage from "@/views/pages/dashboard/fynkus/components/no-data-message.vue";
export default {
    components: {NoDataMessage, spinner },
    name: "CommunityCard",
    props: {
        communities: {
            type: Number,
            default: 0,
        },
        loading: {
          type: Boolean,
          default: false,
        },
    },
    methods: {
        hasCommunityData() {
            return this.communities > 0;
        }
    }
};
</script>

<style>
</style>