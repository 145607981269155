const STATUS_CODE_NO_CONTENT = 204;

export class HttpHelper {

    static async getJson(url, params = null, headers = {}, abortController = null) {
        if (params !== null) {
            url += ('?' + this.jsonToQueryString(params));
        }

        const fetchHeaders = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...headers
        }

        let fetchOptions = {
            method: 'GET',
            headers: fetchHeaders
        };

        if (abortController !== null) {
            fetchOptions.signal = abortController.signal;
        }

        try {
            const response = await fetch(url, fetchOptions);

            if (response.status === STATUS_CODE_NO_CONTENT) {
                return Promise.resolve(null);
            }

            if (false === response.ok) {
                return Promise.reject(await response.json());
            }

            try {
                return await response.json();
            } catch (e) {
                return Promise.reject(e);
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }

    static jsonToQueryString(json) {
        return Object.keys(json).map(function (key) {
          return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
        }).join('&');
      }
}
