<template>
    <div class="text-center w-100 p-2">
        <div class="spinner-border text-success p-4" role="status">
            <span class="sr-only">Cargando datos...</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "Spinner"
}
</script>

<style>
</style>